import { actionQueue } from 'helpers/Queue';
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub';

export const newsletterSubscribe = async (cartAccessToken, signUpData) => {
  const res = await actionQueue.add(() => {
    return fetchApiHub(
      '/action/subscribe/subscribeByEmail',
      { method: 'POST' },
      {
        auth: cartAccessToken,
        user: { ...signUpData },
      },
    );
  });

  return res;
};

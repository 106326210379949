/* eslint-disable prettier/prettier */
// Marketing Cloud Tracking Script (collect.js)

export const marketingCloudTracking = `
    var _etmc = [];
    _etmc.push(["setOrgId", "100012398"]);
`;

export const trackPageView = () => {
  window['_etmc'].push(['trackPageView']);
};

export const trackPDPView = (productSku) => {
  window['_etmc'].push(['trackPageView', { item: productSku }]);
};

export const trackPLPView = (category) => {
  window['_etmc'].push(['trackPageView', { category: category }]);
};

export const trackCartUpdate = (productName, quantity, price, sku) => {
  window['_etmc'].push([
    'trackCart',
    {
      item: productName,
      quantity: `${quantity}`,
      price: `${price}`,
      unique_id: sku,
    },
  ]);
};

export const trackCartClear = () => {
  window['_etmc'].push(['trackCart', { clear_cart: true }]);
};

export const trackCheckout = (data) => {
  const items = data.lineItems.map((item) => {
    return {
      item: item.name,
      quantity: item.count,
      price: item.price.centAmount / 10 ** item.price.fractionDigits,
      unique_id: item.variant.sku,
    };
  });
  window['_etmc'].push([
    'trackConversion',
    {
      cart: JSON.stringify(items),
      order_number: data.orderNumber,
    },
  ]);
};

export const trackUserAttributes = (userEmail) => {
  window['_etmc'].push(['setUserInfo', { email: userEmail }]);
};

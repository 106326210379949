import dynamic from 'next/dynamic';

const AmplienceDynamicContentTastic = dynamic(() => import('./amplience/amplience-content'));
const AmplienceVisualizationTastic = dynamic(() => import('./amplience/amplience-visualization-component'));
const Cart = dynamic(() => import('./cart'));
const Checkout = dynamic(() => import('./checkout'));
const Confirmation = dynamic(() => import('./checkout/confirmation'));
const PaymentError = dynamic(() => import('./checkout/payment-error'));
const PaymentRedirectTastic = dynamic(() => import('./checkout/payment-redirect'));
const NotFound = dynamic(() => import('./not-found'));
const NotFound404 = dynamic(() => import('./notFound404/index'));
const ProductDetails = dynamic(() => import('./products/details'));
const ProductList = dynamic(() => import('./products/product-list'));

export const tastics = {
  'commercetools/ui/checkout': Checkout,
  'commercetools/ui/confirmation': Confirmation,
  'commercetools/ui/payment-error': PaymentError,
  'commercetools/ui/cart': Cart,
  'commercetools/ui/products/details': ProductDetails,
  'commercetools/ui/products/product-list': ProductList,
  'commercetools/ui/notFound404': NotFound404,
  'commercetools/ui/payment-redirect': PaymentRedirectTastic,
  'amplience/content': AmplienceDynamicContentTastic,
  'amplience/visualization-component': AmplienceVisualizationTastic,

  default: NotFound,
};

import { mutate } from 'swr';
import { actionQueue } from '../../../helpers/Queue';
import { fetchApiHub } from '../../lib/fetch-api-hub';

export const getProductImages = async (productId: string): Promise<any> => {
  const res = await fetchApiHub('/action/amplience/productImages', { method: 'POST' }, { productId });

  return res;
};

export const fetchContentByKey = async (key: string): Promise<any> => {
  const res = await fetchApiHub('/action/amplience/fetchContentByKey', { method: 'POST' }, { key });
  return res;
};

export const fetchContentById = async (id: string): Promise<any> => {
  const res = await fetchApiHub('/action/amplience/fetchContentById', { method: 'POST' }, { id });
  return res;
};

export const fetchBulletinArticles = async (filters: any[], pageCursor?: string): Promise<any> => {
  const res = await fetchApiHub('/action/amplience/fetchBulletinArticles', { method: 'POST' }, { filters, pageCursor });
  return res;
};

export const fetchMultipleAmplienceContentItems = async (ids: string[]): Promise<any> => {
  const res = await fetchApiHub('/action/amplience/fetchMultipleAmplienceContentItems', { method: 'POST' }, { ids });
  return res;
};

export const fetchHeaderAndFooter = async (): Promise<any> => {
  const res = await actionQueue.add(() => {
    return fetchApiHub('/action/amplience/fetchHeaderAndFooter', { method: 'POST' });
  });

  mutate('/action/amplience/fetchHeaderAndFooter', res, { revalidate: true });
  return res.data;
};

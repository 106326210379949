import React, { useEffect, useContext, useState } from 'react';
import { GetServerSideProps, Redirect } from 'next';
import Head from 'next/head';
import { Product } from '@Types/product/Product';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import AmplienceNewsletterOverlay from 'components/frontastic-ui/amplience/amplience-newsletter-overlay';
import { defaultOGImage } from 'helpers/constants/amplienceConstants';
import TagManager from 'helpers/googleTagManager/googleTag';
// eslint-disable-next-line import/order
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { trackPDPView, trackPLPView, trackPageView } from 'scripts/collect';
import { LocaleContext } from 'frontastic/provider/locale';
import { createClient, ResponseError, LocaleStorage, fetchApiHubServerSide, useCart } from '../frontastic';
import { FrontasticRenderer } from '../frontastic/lib/renderer';
import { PageDataResponse } from '../frontastic/lib/types';
import { tastics } from '../frontastic/tastics';
import { Log } from '../helpers/errorLogger';
import { useFormat } from '../helpers/hooks/useFormat';

type SlugProps = {
  // This is used for SSR to correctly return the initial html document
  isMobile: boolean;
  // This needs an overhaul. Can be too many things in my opinion (*Marcel)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // data: RedirectResponse | PageDataResponse | ResponseError | { ok: string; message: string } | string;
  locale: string;
  canonicalUrl: string;
  metaTags: any;
};

type metaTagsProps = {
  source: 'amplience' | 'commerce-tools' | '';
  metaTitle: string;
  metaDescription: string;
  openGraphTitle: string;
  openGraphDescription: string;
  openGraphImageUrl: string;
  openGraphType: string;
  openGraphSiteName: string;
  openGraphUrl: string;
};

export default function Slug({ isMobile, data, locale, canonicalUrl, metaTags }: SlugProps) {
  const { formatMessage } = useFormat({ name: 'common' });
  LocaleStorage.locale = locale;
  const ampliencePageSEO = data.data?.dataSources?.['__master']?.pageSEO;
  const dataSources = (data as PageDataResponse)?.data?.dataSources;
  const product: Product = dataSources ? Object.values(dataSources)[0]?.product : undefined;
  const dataContent: any = dataSources?.['__master']?.content;
  const category = dataContent?.productsData?.query?.category;
  const localeContext = useContext(LocaleContext);
  const tagManager = new TagManager();

  useEffect(() => {
    if (dataContent?.productsData?.count > 1 && category) {
      trackPLPView(category);
    } else if (product) {
      trackPDPView(product.productId);
    } else {
      trackPageView();
    }

    tagManager.pageView(dataSources, localeContext.currentLocale);
  }, [data?.pageFolder?.configuration]);
  if (!data || typeof data === 'string') {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }

  if (!data!.ok && data!.message) {
    return (
      <>
        <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900">Internal Error</h1>
        <p className="mt-2 text-lg">{data!.message}</p>
        <p className="mt-2 text-lg">Check the logs of your Frontastic CLI for more details.</p>
      </>
    );
  }
  return (
    <>
      <Head>
        <title>
          {metaTags.source && metaTags.metaTitle
            ? metaTags.metaTitle
            : formatMessage({
                id: 'default.pageTitle',
                defaultMessage: 'Luxury Shirts Designed In London | Thomas Pink',
              })}
        </title>
        <meta
          name="description"
          content={
            metaTags.source && metaTags.metaDescription
              ? metaTags.metaDescription
              : formatMessage({
                  id: 'default.pageDesc',
                  defaultMessage:
                    'Thomas Pink, the past, present and future of English shirtmaking. Shop online now with free returns.',
                })
          }
        />
        <link
          rel="canonical"
          href={ampliencePageSEO && ampliencePageSEO.canonicalUrl ? ampliencePageSEO.canonicalUrl : canonicalUrl}
        />
        {ampliencePageSEO && ampliencePageSEO.doNotIndex && <meta name="robots" content="noindex" />}
        {product ? (
          product.hrefLangLinks.map((linkObject) => {
            return (
              <link
                rel="alternate"
                hrefLang={linkObject.hrefLang.toLowerCase()}
                href={linkObject.hrefLink}
                key={linkObject.hrefLang}
              />
            );
          })
        ) : (
          <>
            {ampliencePageSEO?.hreflangContentCorrelation &&
              ampliencePageSEO?.hreflangContentCorrelation.length > 0 &&
              ampliencePageSEO?.hreflangContentCorrelation.map((content) => (
                <link key={content?.Locale} rel="alternate" hrefLang={content?.Locale} href={content?.Url} />
              ))}

            {/* For default hrefLang */}
            {ampliencePageSEO?.hreflangContentCorrelation &&
              ampliencePageSEO?.hreflangContentCorrelation.length > 0 &&
              ampliencePageSEO?.hreflangContentCorrelation
                .filter((content) => content?.Locale === 'en-GB')
                .map((content) => <link key="x-default" rel="alternate" hrefLang="x-default" href={content?.Url} />)}
          </>
        )}

        <>
          <meta
            property="og:title"
            content={
              metaTags.source && metaTags.openGraphTitle
                ? metaTags.openGraphTitle
                : formatMessage({
                    id: 'default.pageTitle',
                    defaultMessage: 'Luxury Shirts Designed In London | Thomas Pink',
                  })
            }
          />
          <meta property="og:url" content={metaTags.openGraphUrl} />
          <meta
            property="og:description"
            content={
              metaTags.source && metaTags.openGraphDescription
                ? metaTags.openGraphDescription
                : formatMessage({
                    id: 'default.pageDesc',
                    defaultMessage:
                      'Thomas Pink, the past, present and future of English shirtmaking. Shop online now with free returns.',
                  })
            }
          />
          <meta property="og:image" content={metaTags.source ? metaTags.openGraphImageUrl : defaultOGImage} />
          <meta property="og:site_name" content={metaTags.openGraphSiteName} />
          <meta property="og:type" content={metaTags.openGraphType} />
        </>
      </Head>
      <AmplienceNewsletterOverlay />
      <FrontasticRenderer data={data} tastics={tastics} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps | Redirect = async ({ params, locale, query, req, res }) => {
  const userAgent = req.headers['user-agent'];
  const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

  LocaleStorage.locale = locale;

  // URLs should be lowercase only, do a 301 redirect if not
  const slug = (params.slug as string[])?.join('/') || '';
  if (slug !== slug.toLowerCase()) {
    return {
      redirect: {
        destination: slug.toLowerCase(),
        statusCode: 301,
      } as Redirect,
    };
  }

  if (slug !== 'search') {
    res.setHeader('Cache-Control', 'public, max-age=1800, stale-while-revalidate');
  }

  const frontastic = createClient();
  const data = await frontastic.getRouteData(params, locale, query, req, res);

  if (data) {
    if (data instanceof ResponseError && data.getStatus() == 404) {
      return {
        notFound: true,
      };
    } else if (typeof data === 'object' && 'target' in data) {
      return {
        redirect: {
          destination: data.target,
          statusCode: data.statusCode,
        } as Redirect,
      };
    }
  }

  if (data instanceof Error) {
    // @TODO: Render nicer error page in debug mode, which shows the error to
    // the developer and also outlines how to debug this (take a look at
    // frontastic-CLI).
    Log.error('Error retrieving data: ', data);
    return {
      notFound: true,
    };
  }

  if (typeof data === 'string') {
    return {
      props: {
        data: { error: data },
        error: data,
      },
    };
  }

  if ((data as any)!.message === 'Could not resolve page from path') {
    return {
      notFound: true,
    };
  }

  // Set-Cookie headers
  const ranSiteID = query.ranSiteID;
  if (ranSiteID) {
    const date = new Date();
    const year = date.getUTCFullYear().toString();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const formattedTime = `${year}${month}${day}_${hours}${minutes}`;
    const cookieValue = `rmStoreGateway=amid%3A${52653}%7Cald%3A${formattedTime}%7Cauld%3A${Math.floor(
      Date.now() / 1000,
    )}%7Catrv%3A${ranSiteID}; Expires=${new Date(
      Date.now() + 30 * 24 * 60 * 60 * 1000,
    ).toUTCString()}; Domain=.thomaspink.com; Path=/; Secure; SameSite=Lax`;
    res.setHeader('Set-Cookie', cookieValue);
  }

  // Existance of "enforceCorrectUrl" in page data means that we are looking at a PDP which has an incorrect "product name" url slug
  // This needs to be changed and the correct url needs to be enforced
  const dataSource = (data as PageDataResponse)?.data?.dataSources;
  const enforceCorrectPdpUrl = dataSource ? Object.values(dataSource)[0]?.enforceCorrectUrl : '';
  if (enforceCorrectPdpUrl) {
    return {
      redirect: {
        destination: Object.values((data as PageDataResponse)?.data?.dataSources)[0]?.enforceCorrectUrl,
        statusCode: 301,
      } as Redirect,
    };
  }

  const dataSources = (data as PageDataResponse)?.data?.dataSources;
  const product: Product = dataSources ? Object.values(dataSources)[0]?.product : undefined;
  const ampliencePageSEO: any = dataSources ? dataSources['__master']?.pageSEO : undefined;
  const canonicalUrl = `https://${req.headers.host}${locale !== 'us' ? '/' + locale : ''}/${slug}`;
  let metaTags: metaTagsProps = {
    source: '',
    metaTitle: '',
    metaDescription: '',
    openGraphTitle: '',
    openGraphDescription: '',
    openGraphImageUrl: '',
    openGraphType: 'website',
    openGraphSiteName: 'Thomas Pink',
    openGraphUrl: canonicalUrl,
  };
  if (product) {
    const productImages = (await fetchApiHubServerSide(
      `/action/amplience/productImages?productId=${product.variants?.[0]?.attributes?.['TP_PRODUCT_ID']}`,
      {
        req,
        res,
      },
    )) as any;
    metaTags = {
      ...metaTags,
      metaTitle: product.metaTitle ?? `${product.name} | Thomas Pink` ?? '',
      metaDescription: product.metaDescription ?? '',
      openGraphTitle: product.openGraphTitle ?? product.metaTitle ?? `${product.name} | Thomas Pink` ?? '',
      openGraphDescription: product.openGraphDescription ?? product.metaDescription ?? '',
      openGraphImageUrl: `${productImages?.images?.[0]?.src}?h=800`,
      source: 'commerce-tools',
    };
  } else if (ampliencePageSEO) {
    metaTags = {
      ...metaTags,
      metaTitle: ampliencePageSEO.title ?? '',
      metaDescription: ampliencePageSEO.description ?? '',
      openGraphTitle: ampliencePageSEO.openGraph.title ?? ampliencePageSEO.title ?? '',
      openGraphDescription: ampliencePageSEO.openGraph.description ?? ampliencePageSEO.description ?? '',
      openGraphImageUrl:
        ampliencePageSEO.openGraph && ampliencePageSEO.openGraph.contentImage
          ? `${createImageUrlFromAmplienceImageCard(ampliencePageSEO.openGraph)}?w=1200`
          : defaultOGImage,
      source: 'amplience',
    };
  }
  return {
    props: {
      isMobile,
      canonicalUrl,
      data: data || null,
      locale: locale,
      metaTags,
      ...(await serverSideTranslations(locale, [
        'common',
        'cart',
        'product',
        'checkout',
        'account',
        'error',
        'success',
        'wishlist',
        'newsletter',
      ])),
    },
  };
};

import { useRef } from 'react';
import classNames from 'classnames';
import IconClose from '../../icons/close';
import styles from './input-with-label.module.scss';

interface InputWithLabelProps {
  id: string;
  name: string;
  label: string;
  type: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: React.FormEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  message?: string;
  error?: boolean;
  errorMessage?: string;
  dataTestId?: string;
  className?: {
    container?: string;
    containerError?: string;
    input?: string;
    label?: string;
  };
  disabled?: boolean;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  id,
  name,
  label,
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  onClear,
  onInput,
  message,
  error,
  errorMessage,
  dataTestId,
  className,
  onKeyDown,
  readOnly = false,
  disabled = false,
  children,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClear = () => {
    inputRef.current.focus();
    onClear();
  };

  return (
    <div
      className={classNames(styles.container, {
        [className?.containerError ?? styles.container__error]: error,
        [className?.container]: className?.container,
      })}
    >
      <input
        ref={inputRef}
        id={id}
        className={classNames({ [className?.input]: className?.input })}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onInput={onInput}
        onBlur={onBlur}
        onFocus={onFocus}
        data-testid={dataTestId}
        required
        placeholder=""
        onKeyDown={
          onKeyDown ??
          ((e) => {
            e.key === 'Enter' && e.preventDefault();
          })
        }
        disabled={disabled}
        readOnly={readOnly}
        aria-label={label}
      />
      <label
        className={classNames({ [className?.label]: className?.label, [styles.label_default]: !className?.label })}
        htmlFor={name}
      >
        {label}
      </label>
      {children}

      {onClear && (
        <button onClick={handleClear}>
          <IconClose />
        </button>
      )}

      {(message || errorMessage) && (
        <span
          className={classNames(styles.message, {
            [styles.message__visible]: !!message,
          })}
          data-testid={`${dataTestId}_error`}
        >
          {message ? (error ? errorMessage : message) : errorMessage}
        </span>
      )}
    </div>
  );
};

export default InputWithLabel;

import { useContext, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import InputWithLabel from 'components/commercetools-ui/input-with-label';
import Loading from 'components/commercetools-ui/loading';
import NextLink from 'components/common/NextLink';
import IconClose from 'components/icons/close';
// eslint-disable-next-line import/order
import { getNames } from 'country-list';
import TagManager from 'helpers/googleTagManager/googleTag';
import { useFormat } from 'helpers/hooks/useFormat';
import { useOutsideClick } from 'helpers/hooks/useOutsideClick';
import { useStorage } from 'helpers/hooks/useStorage';
import { StringHelpers } from 'helpers/stringHelpers';
import { fetchContentByKey } from 'frontastic/actions/amplience';
import { newsletterSubscribe } from 'frontastic/actions/newsletter';
import { getEnvironmentVariable } from 'frontastic/actions/projectConfiguration';
import { LayoutContext } from 'frontastic/provider/layout';

export interface AmplienceNewsletterOverlayProps {
  main: {
    title: string;
    body: string;
  };
  success: {
    title: string;
    body: string;
  };
  error: {
    title: string;
    body: string;
  };
  promotion: {
    id: string;
    name: string;
  };
  settings: {
    desktop: {
      visible: boolean;
      delay: number;
    };
    mobile: {
      visible: boolean;
      delay: number;
    };
  };
}

enum popupState {
  main,
  error,
  success,
}

const AmplienceNewsletterOverlay: React.FC = () => {
  const [showNewsletterOverlay, setShowNewsletterOverlay] = useState(false);
  const [signUpData, setSignUpData] = useState({ firstName: '', lastName: '', email: '', country: '' });
  const [error, setError] = useState({ firstName: false, lastName: false, email: false, country: false });
  const [newsletterState, setNewsletterState] = useState<popupState>(popupState.main);
  const [countrySelectorOpen, setCountrySelectorOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const data = useRef<AmplienceNewsletterOverlayProps>(null);
  const timerRef = useRef(null);

  const { formatMessage: formatNewsletterMessage } = useFormat({ name: 'newsletter' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { setItem, getItem, getItemJson } = useStorage();
  const ref = useOutsideClick(() => closeOverlay());
  const layout = useContext(LayoutContext);
  const router = useRouter();

  const tagManager = new TagManager();

  const fetchNewsletterOverlay = async () => {
    // The fallback condition can be removed once we are confident the enironment variable is accessible
    const deliveryKey =
      (await getEnvironmentVariable('amplience.newsletterOverlayDeliveryKey')) || 'newsletter-subscription-overlay';

    fetchContentByKey(deliveryKey).then((response) => {
      if (response.data?.content) {
        const res: AmplienceNewsletterOverlayProps = response?.data?.content;
        data.current = res;

        const delay = layout.isDesktop ? res.settings.desktop.delay : res.settings.mobile.delay;
        const visible = layout.isDesktop ? res.settings.desktop.visible : res.settings.mobile.visible;

        if (visible && !['/cart', '/checkout'].includes(router.asPath)) {
          timerRef.current = setTimeout(() => {
            const countries = getNames()?.sort((a, b) => a.localeCompare(b));

            if (window && window['geotargetly_country_name']) {
              const country = window['geotargetly_country_name']();
              if (countries.find((ct) => ct === country)) {
                setSignUpData((data) => ({ ...data, country: country }));
              }
            }

            setCountryList(countries);
            setShowNewsletterOverlay(true);
            tagManager.viewPromotion(res.promotion.id, res.promotion.name);
          }, delay * 1000);
        }
      }
    });
  };

  useEffect(() => {
    if (!getItem('tpNewsletterViewed', 'session') && !data.current) {
      fetchNewsletterOverlay();
    }
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  const handleChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    const value = (e.target as HTMLInputElement)?.value;
    setSignUpData((data) => ({
      ...data,
      [(e.target as HTMLInputElement)?.name]: value,
    }));
  };

  const closeOverlay = () => {
    setShowNewsletterOverlay(false);
    tagManager.closeNewsletter();
    setItem('tpNewsletterViewed', 'true', 'session');
  };

  const validateForm = () => {
    const emptyRegex = /^\s*$/;
    const validation = {
      firstName: emptyRegex.test(signUpData.firstName),
      lastName: emptyRegex.test(signUpData.lastName),
      country: emptyRegex.test(signUpData.country),
      email: !StringHelpers.isEmailValid(signUpData.email),
    };

    setError(validation);
    return validation;
  };

  const handleSubmit = async () => {
    const validation = validateForm();
    if (!Object.values(validation).includes(true)) {
      setIsLoading(true);
      await newsletterSubscribe(getItemJson('cart_token', 'local').access_token, signUpData)
        .then((response) => {
          if (response.statusCode >= 400) {
            setNewsletterState(popupState.error);
          } else {
            setItem('tpNewsletterViewed', 'true', 'session');
            tagManager.signUpToNewsletter();
            setNewsletterState(popupState.success);
          }
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const Form = () => (
    <div data-testid="newsletter-main">
      <div className="font-libertinus text-[32px] font-normal">{data.current?.main?.title}</div>
      <div className="mt-2 mb-6 font-manrope text-sm font-medium leading-[150%] tracking-[0.28px]">
        {data.current?.main?.body}
      </div>
      <div className="flex gap-3">
        <InputWithLabel
          id="firstName"
          dataTestId="signup-newsletter-firstname"
          type="text"
          name="firstName"
          value={signUpData.firstName}
          label={formatCommonMessage({ id: 'firstName', defaultMessage: 'First Name' })}
          onChange={(e) => handleChange(e)}
          error={error.firstName}
          errorMessage={formatErrorMessage({
            id: 'firstName.empty',
            defaultMessage: 'First name should not be empty',
          })}
          className={{
            input: ' !border-[#60514F] bg-pink-brand',
            container: 'mb-2 flex-1',
            label: 'bg-pink-brand !text-[#60514F]',
          }}
        />
        <InputWithLabel
          id="lastName"
          dataTestId="signup-newsletter-lastname"
          type="text"
          name="lastName"
          value={signUpData.lastName}
          label={formatCommonMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
          onChange={(e) => handleChange(e)}
          error={error.lastName}
          errorMessage={formatErrorMessage({
            id: 'lastName.empty',
            defaultMessage: 'Last name should not be empty',
          })}
          className={{
            input: '!border-[#60514F] bg-pink-brand ',
            container: 'mb-2 flex-1',
            label: 'bg-pink-brand !text-[#60514F]',
          }}
        />
      </div>
      <div className="flex">
        <InputWithLabel
          id="email"
          dataTestId="signup-newsletter-email"
          type="text"
          name="email"
          value={signUpData.email}
          label={formatCommonMessage({ id: 'email', defaultMessage: 'Email' })}
          onChange={(e) => handleChange(e)}
          error={error.email}
          errorMessage={formatErrorMessage({
            id: 'email.valid',
            defaultMessage: 'Email must be valid',
          })}
          className={{
            input: '!border-[#60514F] bg-pink-brand',
            container: 'mb-2 flex-1 ',
            label: 'bg-pink-brand !text-[#60514F]',
          }}
        />
      </div>

      <div
        className="relative flex flex-col"
        onClick={() => {
          setCountrySelectorOpen(!countrySelectorOpen);
        }}
      >
        <InputWithLabel
          id="country"
          dataTestId="signup-newsletter-country"
          type="text"
          name="country"
          value={signUpData.country}
          label={formatCommonMessage({ id: 'country', defaultMessage: 'Country' })}
          readOnly={true}
          error={error.country}
          errorMessage={formatErrorMessage({
            id: 'country.selected',
            defaultMessage: 'You must select a country',
          })}
          className={{
            input: 'cursor-pointer !border-[#60514F] bg-pink-brand',
            container: 'relative relative flex-1',
            label: 'bg-pink-brand !text-[#60514F]',
          }}
        >
          {countrySelectorOpen ? (
            <ChevronUpIcon className="absolute right-3 top-1/3 h-3.5" />
          ) : (
            <ChevronDownIcon className="absolute right-3 top-1/3 h-3.5" />
          )}
        </InputWithLabel>
        {countrySelectorOpen && (
          <div className="absolute left-0 right-0 top-12 flex max-h-36 flex-col overflow-auto border-x border-b border-[#60514F] bg-pink-brand">
            <ul>
              {countryList
                .sort((a, b) => a.localeCompare(b))
                .map((country) => (
                  <li
                    className="cursor-pointer py-1 px-4 font-manrope text-sm hover:bg-pink-light/[50%]"
                    key={country}
                    data-testid="country-option"
                    onClick={() => {
                      setSignUpData((data) => ({ ...data, country: country }));
                      setCountrySelectorOpen(false);
                    }}
                  >
                    {country}
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
      <div
        className="mb-4 mt-9 font-manrope text-xs font-medium leading-[128%] tracking-[0.24px]"
        dangerouslySetInnerHTML={{
          __html: formatNewsletterMessage({
            id: 'privacy.policy',
            defaultMessage: 'By signing up to our newsletter you are agreeing to our Privacy Policy.',
          }),
        }}
      ></div>
      <button
        className="w-full bg-black py-8 font-manrope text-[11px] font-medium uppercase tracking-[2.2px] text-white"
        onClick={handleSubmit}
        data-testid="newsletter-submit"
      >
        {formatNewsletterMessage({ id: 'join.now', defaultMessage: 'JOIN NOW' })}
      </button>
    </div>
  );

  const Success = () => (
    <div className="my-32 flex flex-col items-center" data-testid="newsletter-success">
      <div className="text-center font-libertinus text-[32px] font-normal">{data.current?.success?.title}</div>
      <div className="mt-4 text-center font-manrope text-sm font-medium leading-[150%] tracking-[0.28px]">
        {data.current?.success?.body}
      </div>
      <button
        className="mt-10 w-full bg-black py-8 font-manrope text-[11px] font-medium uppercase tracking-[2.2px] text-white"
        data-testid="button-success-continue-shopping"
        onClick={() => {
          setShowNewsletterOverlay(false);
        }}
      >
        {formatNewsletterMessage({ id: 'continue.shopping', defaultMessage: 'CONTINUE SHOPPING' })}
      </button>
    </div>
  );

  const Error = () => (
    <div className="my-32 flex flex-col items-center" data-testid="newsletter-error">
      <div className="text-center font-libertinus text-[32px] font-normal">{data.current?.error?.title}</div>
      <div className="mt-4 text-center font-manrope text-sm font-medium leading-[150%] tracking-[0.28px]">
        {data.current?.error?.body}
      </div>
      <button
        className="mt-10 w-full bg-black py-8 font-manrope text-[11px] font-medium uppercase tracking-[2.2px] text-white"
        data-testid="button-error-continue-shopping"
        onClick={() => {
          setShowNewsletterOverlay(false);
        }}
      >
        {formatNewsletterMessage({ id: 'continue.shopping', defaultMessage: 'CONTINUE SHOPPING' })}
      </button>
      <NextLink className="mt-6 font-manrope font-medium uppercase tracking-[2.2px] underline" href={'/contact-us'}>
        {formatNewsletterMessage({ id: 'contact.support', defaultMessage: 'CONTACT SUPPORT' })}
      </NextLink>
    </div>
  );

  return (
    <Transition
      show={showNewsletterOverlay}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className="fixed top-0 left-0 bottom-0 right-0 z-[1000] h-full  w-full cursor-newsletterX bg-black/50"
        data-testid="amplience-newsletter-overlay"
      >
        <div
          className={`${
            layout.isMobile ? 'min-h-[70%]  w-full px-4 py-10' : 'left-10 h-[560px] w-[540px] p-8'
          } absolute bottom-0 flex cursor-default flex-col justify-center justify-center bg-pink-brand `}
          ref={ref}
        >
          <button
            className="absolute top-4 right-4"
            data-testid="newsletter-close-button"
            onClick={() => closeOverlay()}
          >
            <IconClose className=" h-7 w-7 cursor-pointer" />
          </button>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <Loading color="#333333" />
            </div>
          ) : newsletterState === popupState.main ? (
            Form()
          ) : newsletterState === popupState.success ? (
            <Success />
          ) : (
            <Error />
          )}
        </div>
      </div>
    </Transition>
  );
};

export default AmplienceNewsletterOverlay;

import Link from 'next/link';

const NextLink = ({ children, href, className = '', target = undefined, ...props }) =>
  href ? (
    <Link href={href} {...props}>
      <a className={className} target={target}>
        {children}
      </a>
    </Link>
  ) : (
    <>{children}</>
  );

export default NextLink;
